import React from 'react'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'

import { HeadingCSS, SubheadingCSS, NavLabelCSS } from '../../styles/typography'
import { breakpoints } from '../../styles/breakpoints'

import Wrapper from '../wrapper/wrapper'

const Container = styled.header`
  padding: 24px 0;
  text-align: center;

  @media ${breakpoints.laptop} {
    padding: 48px 0;
  }

  @media ${breakpoints.wide} {
    padding: 96px 0 72px;
  }
`

const Subheading = styled.span`
  ${SubheadingCSS}
  display: inline-block;
  margin-bottom: 16px;

  @media ${breakpoints.laptop} {
    margin-bottom: 24px;
  }

  @media ${breakpoints.wide} {
    margin-bottom: 32px;
  }
`

const Heading = styled.h1`
  ${HeadingCSS};
  margin-bottom: 24px;

  @media ${breakpoints.laptop} {
    margin-bottom: 40px;
  }

  @media ${breakpoints.wide} {
    margin-bottom: 48px;
  }
`

const Nav = styled.nav`
  display: flex;
  justify-content: center;
`

const NavItem = styled.div`
  ${NavLabelCSS}
  display: flex;
  justify-content: center;
  margin: 0 12px;
  position: relative;
  width: 100px;

  @media ${breakpoints.wide} {
    width: 120px;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  transition: color 0.2s;

  &:hover {
    color: grey;
  }

  &::before {
    background-color: transparent;
    content: '';
    height: 1px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%);
    width: 100%;
  }

  &.active {
    pointer-events: none;

    &::before {
      background-color: black;
    }
  }

  &.current-page {
    pointer-events: none;
  }
`

export default function Header() {
  const location = useLocation()
  const currentPath = location.pathname
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  return (
    <Container>
      <Wrapper>
        <Subheading>
          Hair Stylist
        </Subheading>
        <Heading>
          <StyledLink to={`/`} className={currentPath === '/' ? 'current-page' : ''}>
            {site.siteMetadata.title}
          </StyledLink>
        </Heading>
        <Nav>
          <NavItem>
            <StyledLink
              to={`/`}
              className={currentPath === '/' ? 'active' : ''}
            >
              Portfolio
            </StyledLink>
          </NavItem>
          <NavItem>
            <StyledLink
              to={`/profile`}
              className={currentPath === '/profile' ? 'active' : ''}
            >
              Profile
            </StyledLink>
          </NavItem>
          <NavItem>
            <StyledLink
              to={`/contact`}
              className={currentPath === '/contact' ? 'active' : ''}
            >
              Contact
            </StyledLink>
          </NavItem>
        </Nav>
      </Wrapper>
    </Container>
  )
}
