import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'

import { FooterTextCSS } from '../../styles/typography'
import { breakpoints } from '../../styles/breakpoints'

import Wrapper from '../wrapper/wrapper'

const Container = styled.footer`
  width: 100%;
`

const Inner = styled.div`
  border-top: 1px solid black;
  margin: auto;
  max-width: 1440px;
  padding: 48px 0;

  @media ${breakpoints.laptop} {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
  }

  @media ${breakpoints.wide} {
    max-width: 2000px;
  }
`

const Nav = styled.nav`
  margin-bottom: 48px;

  @media ${breakpoints.laptop} {
    display: flex;
    margin-bottom: 0;
  }
`

const NavItem = styled.div`
  margin-bottom: 24px;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${breakpoints.laptop} {
    margin-bottom: 0;
    margin-right: 24px;
    text-align: left;
  }

  @media ${breakpoints.wide} {
    margin-right: 40px;
  }

  a {
    ${FooterTextCSS}
    text-decoration: none;
    transition: color 0.2s;

    &:hover {
      color: grey;
    }
  }
`

const Small = styled.small`
  ${FooterTextCSS}
  display: block;
  text-align: center;

  @media ${breakpoints.laptop} {
    text-align: right;
  }
`

export default function Footer(props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  const date = new Date()
  const year = date.getFullYear()

  return (
    <Container>
      <Wrapper>
        <Inner>
          <Nav>
            <NavItem>
              <Link to={`/`}>
                Portfolio
              </Link>
            </NavItem>
            <NavItem>
              <Link to={`/profile`}>
                Profile
              </Link>
            </NavItem>
            <NavItem>
              <Link to={`/contact`}>
                Contact
              </Link>
            </NavItem>
            <NavItem>
              <a href="https://instagram.com/khairstudio" target="_blank" rel="noreferrer">
                Instagram
              </a>
            </NavItem>
          </Nav>

          <Small>&copy; {site.siteMetadata.title} {year} – all rights reserved.</Small>
        </Inner>
      </Wrapper>
    </Container>
  )
}