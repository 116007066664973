import { css } from 'styled-components'

import { breakpoints } from './breakpoints'

export const HeadingCSS = css`
  font-family: 'Times', Serif;
  font-size: 2.4rem;
  letter-spacing: 0.12em;
  line-height: auto;
  padding-left: 0.12em;
  text-transform: uppercase;

  @media ${breakpoints.laptop} {
    font-size: 3.6rem;
    letter-spacing: 0.063em;
    line-height: auto;
    padding-left: 0.063em;
  }

  @media ${breakpoints.wide} {
    font-size: 5rem;
  }
`;

export const Heading2CSS = css`
  font-family: Helvetica, Sans-Serif;
  font-size: 2.1rem;
  line-height: 1;

  @media ${breakpoints.laptop} {
    font-size: 2.4rem;
    line-height: 1.2;
  }

  @media ${breakpoints.wide} {
    font-size: 3rem;
  }
`

export const SubheadingCSS = css`
  font-family: 'Times', Serif;
  font-size: 1.2rem;
  letter-spacing: 0.12em;
  line-height: 1.5;
  padding-left: 0.12em;
  text-transform: uppercase;

  @media ${breakpoints.laptop} {
    font-size: 1.4rem;
    letter-spacing: 0.2em;
    line-height: 1.9;
    padding-left: 0.2em;
  }

  @media ${breakpoints.wide} {
    font-size: 2rem;
  }
`

export const NavLabelCSS = css`
  font-family: Helvetica, Sans-Serif;
  font-size: 1.4rem;

  @media ${breakpoints.laptop} {
    font-size: 1.6rem;
  }

  @media ${breakpoints.wide} {
    font-size: 2rem;
  }
`

export const MainBodyCSS = css`
  font-family: Helvetica, Sans-Serif;
  font-size: 1.6rem;
  line-height: 1.4;

  @media ${breakpoints.wide} {
    font-size: 2rem;
    line-height: 1.6;
  }
`

export const MetaBodyCSS = css`
  font-family: 'Times', Serif;
  font-size: 1.6rem;
  letter-spacing: 0.013em;
  line-height: 1.6;

  @media ${breakpoints.laptop} {
    line-height: 1.8;
  }

  @media ${breakpoints.wide} {
    font-size: 2rem;
  }
`

export const FooterTextCSS = css`
  font-family: Helvetica, Sans-Serif;
  font-size: 1.4rem;
  line-height: 1.3;

  @media ${breakpoints.wide} {
    font-size: 1.8rem;
  }
`

export const SubtitleCSS = css`
  font-family: Helvetica, Sans-Serif;
  font-size: 1.4rem;
  line-height: 1.4;

  @media ${breakpoints.wide} {
    font-size: 2rem;
  }
`
