import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Helvetica, Sans-Serif;
    font-size: 62.5%;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: inherit;
    outline: none;

    &.no-underline {
      text-decoration: none;
    }
  }

  button {
    background-color: inherit;
    border: 0;
    color: black;
    padding: 0;
    cursor: pointer;
    outline: none;
  }
`

export default GlobalStyle
