import React from 'react'
import styled from 'styled-components'

import { breakpoints } from '../../styles/breakpoints'

const Container = styled.div`
  padding: 0 16px;

  @media ${breakpoints.laptop} {
    padding: 0 24px;
  }
`

export default function Wrapper({ children }) {
  return <Container>{children}</Container>
}
