import React from 'react'
import { useLocation } from '@reach/router'
import { Normalize } from 'styled-normalize'
import styled from 'styled-components'

import GlobalStyle from '../../styles/global-style'
import { breakpoints } from '../../styles/breakpoints'

import Header from '../header/header'
import Footer from '../footer/footer'

const Main = styled.main`
  padding: 24px 0 48px;

  @media ${breakpoints.desktop} {
    padding: ${props => props.currentPath === '/' ? '0' : '48px'} 0 96px;
  }

  @media ${breakpoints.wide} {
    padding-bottom: 120px;
  }
`

export default function Layout({ children }) {
  const location = useLocation()
  const currentPath = location.pathname

  return (
    <>
      <Normalize />
      <GlobalStyle />

      <Header />
      <Main currentPath={currentPath}>
        {children}
      </Main>
      <Footer />
    </>
  )
}
